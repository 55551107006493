import React from 'react'
import '../css/site.css';

export default function OrderList() {
    //need to change this to take in an array for the custom orders and status like a state machine
    return(
        <div className="card-row no-wrap">
            <div className="purple-card">
                <h3>Status</h3>
                <p>Comissions: Open!
                    <br></br>
                    <br></br>
                    <img height="210px" src="images/ducktayto.png" alt="ducktayto personal growth"/>
                </p>
                <br></br>
            </div>
            <div className="card transparent">
                <h3>Currently Working on</h3>                
                <h4>Commissions</h4>
                <p> 1. Open!<br></br>
                2. 
                </p>
                <h4>Upcoming Patterns</h4>
                <p>
                    TFT Dango (free)<br></br>
                    Fat Bean-Shaped Bird <br></br>
                    TFT Shork
                </p>
                <h4>Personal Projects</h4>
                <p>
                Camellia Sweater<br/>
                </p>
                <br></br>
            </div>
            <div className="card transparent">
                <h3>Recent Amigurumi</h3>
                <img className="card-img" src="images/gallery/nahida.jpg" alt="Nahida"/>
                <p>Nahida<br></br>
                11/10/24</p>
            </div>
            <div className="card transparent">
                <h3>Recent Outfit</h3>
                <img className="card-img" src="images/gallery/gemma-cardi.jpg" alt="diamond cardigan"/>
                <p>Gemma Cardigan<br></br>
                    9/14/24</p>
            </div>
        </div>
    );
}